import { Link } from "react-router-dom";

export default function CommunityHome() {
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-sm-12 col-lg-8 p-5 pb-3 pt-lg-5 pt-2">
            

            <div className="clearfix mb-3">
              <h1 className="float-start" style={{ color: "#f1f1f1" }}>Community</h1>  
            </div>
            
            <div className="m-2">
              <Link to="../community" style={{ textDecoration: "none" }}>
                <div className="rounded p-4" style={{ background: "#65295f", color: "#f1f1f1", width: "100%" }}>
                  <h4 style={{ color: "#f1f1f1" }}>Home Rules</h4>
                  <p style={{ color: "#f1f1f1" }}>Return back to the general list of rules</p>
                </div>
              </Link>
            </div>

      
              <h4 style={{ color: "#f1f1f1" }}>Rules</h4>


              <div className="rounded-top p-4 pb-1" style={{ background: "#65295f", color: "#f1f1f1", width: "100%" }}>
                <h4 style={{ color: "#f1f1f1" }}>Rules</h4>
                <p style={{ color: "#f1f1f1" }}>by <i>Admin</i> | Last Updated: today at 21:11</p>
                <hr style={{ borderColor: "#f1f1f1" }} />
                <p style={{ color: "#f1f1f1" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit... [truncated for brevity]</p>
                <hr style={{ borderColor: "#f1f1f1" }} />
              </div>


              <div className="rounded p-4 pb-1" style={{ background: "#65295f", color: "#f1f1f1", width: "100%" }}>
                <p style={{ color: "#f1f1f1" }}>Reply by: <i>CoolPenguin123</i> | today at 21:24</p>
                <hr style={{ borderColor: "#f1f1f1" }} />
                <p style={{ color: "#f1f1f1" }}>why should I follow the rules</p>
                <hr style={{ borderColor: "#f1f1f1" }} />
              </div>


              <div className="rounded p-4" style={{ background: "#65295f", color: "#f1f1f1", width: "100%" }}>
                <p style={{ color: "#f1f1f1" }}><strong>Add your reply</strong></p>
                <div className="input-group">
                  <input className="form-control" style={{ minHeight: "50px", background: "#f1f1f1", color: "#65295f" }} type="text" aria-label="form-input" />
                  <button className="btn btn-success rounded-end">send it</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}
