import React, { useState, useEffect } from "react";
import "../index.css";
//import FoodPageRadioButtonMeals from "./FoodPageRadioButtonMeals";
import api from "../services/api";
//import Dropdown from './FoodPageDropdown';


const getDateTimeForPicker = () => {
    const now = new Date();
    return now.toISOString().slice(0, 16); // formats to "yyyy-MM-ddTHH:mm"
  };

const default_state = {
    "category":"Breakfast",
    "food_name":"",
    "quantity":1,
    "description":"",
    "time_period": getDateTimeForPicker(),
}

function FoodPageRadioButtonFoodWater() {
    const [selectedOption, setSelectedOption] = useState("Food");
    const [foodObject, setFoodObject] = useState(default_state);
    const [foodFormState, setFoodFormState] = useState({ isLoading : true,isSubmitting: false})
    const [foodHistory, setFoodHistory] = useState([]);
    const [waterHistory, setWaterHistory] = useState([]); // State for water history
    const [waterCups, setWaterCups] = useState("");
    const [waterLiters, setWaterLiters] = useState("");
    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };


    async function handleAddWater() {
        const newWaterEntry = {
            quantity: 4,// waterCups ? `${waterCups} cups` : `${waterLiters} liters`,
            date: new Date().toLocaleString('en-GB', {
                year: 'numeric',
                month: 'numeric', 
                day: 'numeric',
                hour: 'numeric',
                minute: '2-digit'
            })
        };
        await api.post("account/water/add",newWaterEntry).then(success => {
            setWaterHistory([...waterHistory, newWaterEntry]);  // Add new entry to water history
            setWaterCups("");  // Clear input fields
            setWaterLiters("");
        }, error =>
        {
            console.log("error" + error);
        }
        );
    };

    async function postFoodUpdate(){
        setFoodFormState({...foodFormState, isSubmitting: true}); 
        // eslint-disable-next-line
        if (!foodObject.food_name.trim() == ""){
            console.log(foodObject);
            await api.post("account/food/add",foodObject).then(success => {
                let foodCopy = {...foodObject, timestamp: new Date().toISOString()};
                setFoodObject(default_state);
                setFoodHistory([...foodHistory,foodCopy]);

            }, error =>{
                // Do some error handling as you wish. 
            });
        }
        setFoodFormState({...foodFormState, isSubmitting: false}); 
        
    }



    useEffect(() => {
        //Run async function in use effect. 
        const fetchData = async () => {
            let now = new Date();
            const today = now.toISOString().split("T")[0];

            await api.get("account/food/list", {params: { from_date: today, to_date: today }})
            .then(success => {
                setFoodHistory(success.data);
            },
            error => {
                //Do some processing here.
                console.log("error occured. ");
            });
            await api.get("account/water/list", {params: { from_date: today, to_date: today }}).then(success => {
                console.log("got data", success.data)
                setWaterHistory(success.data) }, 
            error =>{
                console.log("error occured while loading water history " );
            })
        }
        setFoodFormState({...foodFormState, isLoading:false});

        fetchData();
    },[]);

    return (
        <div>
            <div className="FoodPage-div-width">
                <label className={`btn FoodPage-food-water-switcher FoodPage-rounded-corners-left FoodPage-width align-self-center FoodPage-hover ${selectedOption === "Food" ? "selected highlight" : ""}`}>
                    <input
                        type="radio"
                        value="Food"
                        checked={selectedOption === "Food"}
                        onChange={handleOptionChange}
                    />
                    <h2 className="FoodPage-center">Food</h2>
                </label>
                <label className={`btn FoodPage-food-water-switcher FoodPage-rounded-corners-right FoodPage-width align-self-center FoodPage-hover ${selectedOption === "Water" ? "selected highlight" : ""}`}>
                    <input
                        type="radio"
                        value="Water"
                        checked={selectedOption === "Water"}
                        onChange={handleOptionChange}
                    />
                    <h2 className="FoodPage-center">Water</h2>
                </label>
            </div>

            <br />

            {/* Conditionally rendered sections */}
            {selectedOption === "Food" && (
                <div>
                    <div class="row">
                        <div className="col-lg-6">
                            {/* meals dropdown */}

                            <h2 className="FoodPage-left">When did you eat:</h2>

                            <input
                                type="datetime-local"
                                id="foodpage_dropdown"
                                name="food_time"
                                className={"p-3"}
                                value={foodObject.time_period}
                                onChange={(e) => setFoodObject({...foodObject, time_period: e.target.value})}
                                 /> 
                            
                        
                            {/* form for food */}
                            <div className="FoodPage-form-div-width">
                                <br />
                                
                                <button type="submit" className="btn FoodPage-hover FoodPage-scan-barcode">Scan Barcode</button>
                                <br />
                                <br />
                                <h3>Food Name:</h3>
                                <textarea
                                    className="form-control"
                                    rows="1"
                                    value={foodObject.food_name}
                                    onChange={e => setFoodObject((prevState) => ({...prevState, food_name: e.target.value}))}
                                ></textarea>
                                <br />
                                <h3>Description (optional):</h3>
                                <textarea
                                    className="form-control"
                                    rows="2"
                                    value={foodObject.description}
                                    onChange={e => setFoodObject((prevState) => ({...prevState, description: e.target.value}))}
                                ></textarea>
                                <br />
                                <button type="button" className="btn FoodPage-hover FoodPage-add" onClick={postFoodUpdate} disabled={foodFormState.isSubmitting}>
                                    Add
                                </button>
                            </div>
                        </div>


                        <div className="col-lg-6 FoodPage-history-right">
                            {/* food history data */}
                            <div>
                                <h2 className="FoodPage-center">Food History</h2>
                                <div className="FoodPage-history">
                                    {!foodFormState.isLoading &&
                                    foodHistory.map((entry, index) => (
                                        <div key={index} className="FoodPage-food-in-list">
                                            <div className="FoodPage-history-left">

                                                <p className="me-2"><b>{entry.food_name}</b></p>
                                            </div>
                                            <div className="FoodPage-history-left">
                                                <p>{entry.description}</p>
                                            </div>
                                            <div className="FoodPage-history-right">
                                                <p>{entry.time_period}</p>
                                            </div>
                                        </div>
                                    ))
                                    }
                                    {foodFormState.isLoading &&
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    }
                                </div>
                                <br />
                            </div>
                        </div>
                        </div>
                </div>
            )}

            {selectedOption === "Water" && (
                <div>
                    <div class="row">
                        <div className="col-lg-6">

                            <h3>Amount of cups:</h3>
                            <textarea
                                className="form-control"
                                rows="1"
                                value={waterCups}
                                onChange={(e) => setWaterCups(e.target.value)}
                            ></textarea>
                            <br />
                            <h4>And/Or</h4>
                            <br />
                            <h3>Amount of liters:</h3>
                            <textarea
                                className="form-control"
                                rows="1"
                                value={waterLiters}
                                onChange={(e) => setWaterLiters(e.target.value)}
                            ></textarea>
                            <br />
                            <button type="button" className="btn FoodPage-hover FoodPage-add" onClick={handleAddWater}>
                                Add
                            </button>
                        </div>


                        <div className="col-lg-6 FoodPage-history-right">

                            <div>
                                <h2 className="FoodPage-center">Water History</h2>
                                <div className="FoodPage-history">
                                    {waterHistory.map((entry, index) => (
                                        <div key={index} className="FoodPage-food-in-list">
                                            <div className="FoodPage-history-left">
                                                <b>{entry.quantity}</b>
                                            </div>
                                            <div className="FoodPage-history-right">
                                                {entry.timestamp}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default FoodPageRadioButtonFoodWater;
