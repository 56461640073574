import {Button, InputGroup, Form, FloatingLabel, Collapse} from "react-bootstrap";
import authenticationService from "../services/authentication.service";
import React, {useState} from 'react';
import { Link } from 'react-router-dom';
// import tokenService from "../services/token.service";
// import validator from 'validator'



export default function Login() {
  async function loginButton(e) {
    e.preventDefault();
    console.log('You clicked submit.');
    
    const errorMessages = {}
    if (usermail === ""){
      errorMessages.email = "Email cannot be blank"
    } else if (!(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(usermail))){
      errorMessages.email = "Email not in correct format"
    }

    // if (userpass === ""){
    //   errorMessages.password = "Password cannot be blank"
    // } else if (!validator.isStrongPassword(userpass, {minLength: 8, minNumbers: 1, minSymbols: 1 })){
    //   errorMessages.password = "Password does not meet compelxity requirements"
    // }

    if (Object.keys(errorMessages).length === 0){
      setLoading(true);
      await authenticationService.login(usermail, userpass).then(success => {
        setLoading(false);
        //console.log(authenticationService.isAdmin())
        if (authenticationService.isAdmin()){
          window.location.replace('/admin');
        } else {
          window.location.replace('/account');
        }
    }, error => {
        errorMessages.backend = "computer says no!"
        //setLoginError(true);
        setLoading(false);
        setErrors(errorMessages)
    });
    } else {
      setErrors(errorMessages)
    }
    
  }
  
  const [passwComplex, setpasswComplex] = useState(false);
  const [errorMessages, setErrors] = useState({});
  const [usermail,setUsermail] = useState("");
  const [userpass, setUserpass] = useState("");
  let [loading, setLoading] = useState(false);
   return (
    <div className="container-fluid">
        <div className="row">
            <div className="col-lg-2"></div>
                <div className="col-sm-12 col-lg-8">
                  <div> 
                      <div className="container-fluid accountpage-div">
                          <div className="row">
                              <div className="col-lg-6 col-sm-12 p-5 pb-3 pt-xl-5 pt-2">
                              <h2>Log in to Healthify</h2>
                              <p>By using this service, you agree to our <Link to="../terms/" style={{color: "black"}}>terms and conditions</Link>.</p>
                              <button className={"btn-light rounded-top p-2"} onClick={() => setpasswComplex(!passwComplex)} aria-controls="password-complexity" aria-expanded={passwComplex} style={{width: "100%", border: "none"}}><i className="bi bi-caret-down-square-fill"></i> Password complexity requirements</button>
                              <Collapse in={passwComplex}>
                                <div id="password-complexity" className="rounded-bottom bg-light p-2 pb-1">
                                  <ul>
                                    <li>Minimum length: 8</li>
                                    <li>At least 1 lowercase letter</li>
                                    <li>At least 1 uppercase letter</li>
                                    <li>At least 1 number</li>
                                    <li>At least 1 symbol</li>
                                  </ul>
                                </div>
                              </Collapse>
                              </div>
                              <div className="col-lg-6 col-sm-12 p-1 p-lg-5 pt-0 pt-xl-5 pb-3">
                                <div className="p-2">
                                <div className="m-1">
                                    <FloatingLabel controlId="email" label="E-Mail Address">
                                    <Form.Control type="email" name="email" onChange={(e) => setUsermail(e.target.value)} placeholder="name@example.com"/>
                                    </FloatingLabel>
                                  </div>

                                  <div className="m-1">
                                    <FloatingLabel controlId="password" label="Password">
                                    <Form.Control type="password" name="password" placeholder="Password" onChange={(e) => setUserpass(e.target.value)}/>
                                    </FloatingLabel>
                                    <p>Forgot your password?</p>
                                  </div>
                                  
                                  

                                  <InputGroup style={{width: "100%"}}>
                                    <Button style={{width: "50%"}} variant="primary" type="submit" value="LOGIN" onClick={loginButton} disabled={loading}>Sign In {loading&& <span className="spinner-border spinner-border-sm"></span>}</Button> 
                                    <Button style={{width: "50%"}} variant="secondary" href="./register" value="REGISTER">Register</Button> 
                                </InputGroup>
                                  {Object.entries(errorMessages).map(([key, error]) => (<span key={`${key}: ${error}`} style={{fontWeight: "bold"}}>{error}<br /></span>))}
                              </div>
                      </div>
                    </div>
                  </div>
                <div className="col-lg-2"></div>
              </div>
            <div>
          </div>
        </div>
      </div>
    </div>
  );  
}  
 