import { useLocation, Link } from "react-router-dom";

export default function AccountRedeemTemplate() {
  const location = useLocation();
  const awardData = location.state;

  if (!awardData) {
    window.location.replace('/account/redeem');
    return null;
  }

  return (
    <div>
        <div  className="container-fluid">
            <div  className="row">
            <div className="jumbotron-fluid">
                  <div  className="container p-5 pb-3 pt-lg-5 pt-2" style={{backgroundColor: "gainsboro", color: "black"}}>
                  <h2>{awardData.title}</h2>
                  <h3>{awardData.value} points</h3>
                    <hr></hr>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                  </div>
              </div>
                <div  className="col-lg-2"></div>
                <div  className="col-lg-4 p-5 pb-0 pt-lg-5 pt-2">
                <h2>About this reward</h2>
                </div>
                <div  className="col-lg-4 p-5 pb-2 pb-lg-0 pt-lg-5 pt-0">
                <div  className="input-group">
                    <button  className="btn float-lg-end btn-danger rounded-start p-2" style={{width: "50%"}}>
                      <h2 ><Link to="/account/redeem" style={{textDecoration: 'none', color: 'black'}}>Return</Link></h2>
                    </button>
                    <button  className="btn btn-success rounded-end p-2" style={{width: "50%"}}>
                      <h2><Link to="/account/redeem/success" state={awardData} style={{textDecoration: 'none', color: 'black'}}>Redeem</Link></h2>
                    </button>
                  </div>
                  
                </div>
                <div  className="col-lg-2"></div>
                <div  className="col-lg-2"></div>
                <div  className="col-sm-12 col-lg-8 p-5 pb-3 pt-0">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    <h3>Delivered through: <b>electronic mail</b></h3>
                    <h3>Delivered by: <b>me!</b></h3>
                </div>
              </div>
          </div>
    </div>
  );
}
