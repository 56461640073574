import { Link } from "react-router-dom";
import tokenService from "../services/token.service";
import { useEffect, useState } from "react";
import api from '../services/api';


export default function AccountHome() {
  const taskOne = {taskName:'Task One'}
  const taskTwo = {taskName:'Task Two'}
  const [points, setPoints] = useState(null);

  useEffect(() => {
    //Run async function in use effect. 
    const fetchData = async () => {
        await api.get("user/points",)
        .then(success => {
            setPoints(success.data);
        },
        error => {
            console.log("Unable to load points");
        });
    }
    fetchData();
  },[]);
  return (
    <div>
        <div  className="container-fluid">
            <div  className="row">
                <div  className="col-lg-2"></div>
                    <div  className="col-sm-12 col-lg-8">
                      <div>  {/* User Information :3 */}
                          <div  className="container-fluid accountpage-div">
                              <div  className="row">
                                  <div  className="col-lg-6 p-5 pb-3 pt-lg-5 pt-2">
                                  <h2>Welcome Back, <b>{tokenService.getFirstName()}!</b></h2>
                                  <h3>How are you feeling today?</h3>
                                  </div>
                                  <div  className="col-lg-6 p-5 pt-0 pt-lg-5 pb-3">
                                    <h2  className="text-lg-end placeholder-wave">{points? <p>You currently have   <b>{points}</b> points </p>: <span className="placeholder col-12"></span> }</h2>
                                  </div>
                              </div>
                          </div>
                          <div  className="container-fluid accountpage-div">
                              <div  className="row">
                                  <div  className="col-lg-6 p-5 pt-3 pb-3">
                                    <Link style={{textDecoration: `none`}}to="task" state={taskOne}>
                                    <button  className="accountpage-task rounded" id="openModal">
                                    <h3>Description of Task One</h3>
                                    </button>
                                    </Link>
                                  </div>
                                  <div  className="col-lg-6 p-5 pt-1 pt-lg-3 pb-3">
                                  <Link style={{textDecoration: `none`}}to="task" state={taskTwo}>
                                    <button  className="accountpage-task rounded" id="openModal">
                                    <h3>Description of Task Two</h3>
                                    </button>
                                    </Link>
                                  
                                  </div>
                              </div>
                          </div>
                          <h3  className="pt-1 pt-lg-3 ps-5">Manage Tracking</h3>
                          <div  className="container-fluid accountpage-div">
                              <div  className="row">
                                  <div  className="col-lg-3 col-6 p-2">
                                    <Link style={{textDecoration: `none`}}to="tracking/food">
                                        <div  className="accountpage-button d-flex rounded p-0 " style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/bridge.jpg'})`, backgroundSize: 'cover'}}>
                                          <div  className="accountRedeem-caption align-self-end p-3 pb-0">
                                            <p  className="accountpage-buttonText text-start align-text-bottom">Food and Water</p>
                                          </div>
                                        </div>
                                    </Link>
                                  </div>
                                  <div  className="col-lg-3 col-6 p-2">
                                  <Link style={{textDecoration: `none`}}to="tracking/mood">
                                      <div  className="accountpage-button d-flex rounded p-0 " style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/bridge.jpg'})`, backgroundSize: 'cover'}}>
                                          <div  className="accountRedeem-caption align-self-end p-3 pb-0">
                                            <p  className="accountpage-buttonText text-start align-text-bottom">Mood and Sleep</p>
                                          </div>
                                        </div>
                                    </Link>
                                  </div>
                                  <div  className="col-lg-3 col-6 p-2">
                                        <div  className="accountpage-button d-flex rounded p-0 " style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/bridge.jpg'})`, backgroundSize: 'cover'}}>
                                          <div  className="accountRedeem-caption align-self-end p-3 pb-0">
                                            <p  className="accountpage-buttonText text-start align-text-bottom">Activity</p>
                                          </div>
                                        </div>
                                  </div>
                                  <div  className="col-lg-3  col-6 p-2">
                                        <div  className="accountpage-button d-flex rounded p-0 " style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/bridge.jpg'})`, backgroundSize: 'cover'}}>
                                          <div  className="accountRedeem-caption align-self-end p-3 pb-0">
                                            <p  className="accountpage-buttonText text-start align-text-bottom">Period</p>
                                          </div>
                                        </div>
                                  </div>
                                  </div>
                              </div>
                          </div>
                        
                        <h3  className="pt-1 pt-lg-3 ps-5">More</h3>
                          <div  className="container-fluid">
                              <div  className="row">
                                  <div  className="col-lg-3 col-6 p-2">
                                    <Link style={{textDecoration: `none`}}to="./redeem">
                                        <div  className="accountpage-lessimportant rounded p-3">
                                        <p  className="accountpage-buttonText text-start align-text-bottom">Redeem Points</p>
                                        </div>
                                    </Link>
                                  </div>
                                  <div  className="col-lg-3 col-6 p-2">
                                  <a style={{textDecoration: `none`}} href="https://scheduler.zoom.us/mgostic">
                                        <div  className="accountpage-lessimportant rounded p-3">
                                        <p  className="accountpage-buttonText text-start ">Book a Consultation</p>
                                        </div>
                                      </a>
                                  </div>
                                  <div  className="col-lg-3 col-6 p-2">
                                    <Link to="settings" style={{textDecoration: `none`}}>
                                      <div  className="accountpage-notimportant rounded p-3">
                                        <p  className="accountpage-buttonText text-start ">Account Settings</p>
                                        </div>
                                    </Link>
                                  </div>
                                  <div  className="col-lg-3 col-6 p-2">
                                    <Link to="../contact" style={{textDecoration: `none`}}>
                                    <div  className="accountpage-notimportant rounded p-3">
                                      <p  className="accountpage-buttonText text-start ">Contact</p>
                                      </div>
                                    </Link>
                                  </div>                                  
                                  </div>
                              </div>
                      </div>
                <div  className="col-lg-2"></div>            
            </div>
        </div>
    </div>
  );
}