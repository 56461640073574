import { Link } from "react-router-dom";
import { useState, useEffect } from 'react';
import api from '../services/api';
// import { Form } from "react-bootstrap";

function Redeemable({currentPoints, value}){
    if (currentPoints >= value){
        return <p  className="float-end small">You can redeem this</p>
    } else {
        return <p  className="float-end small">Not enough points</p>
    }
}

export default function AccountRedeem() {
    const [searchTerm, setSearchTerm] = useState('');
    // const [showAvailable, reverseAvailable] = useState(false);
    const awards = [
        {title:"The Concept of the Forth Bridge", value:10,},
        {title:"A really cool photo of the Forth Bridge", value:2,},
        {title:"A Forth Bridge experience", value:700,},
        {title:"Forth Bridge: the photo", value:3,},
    ];

    const filteredAwards = awards.filter(award => 
        award.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const [points, setPoints] = useState(null);

    useEffect(() => {
      //Run async function in use effect. 
      const fetchData = async () => {
          await api.get("user/points",)
          .then(success => {
              setPoints(success.data);
          },
          error => {
              console.log("Unable to load points ");
          });
      }
      fetchData();
    },[]);


  return (
    <div>
        <div  className="container-fluid">
            <div  className="row">
                <div  className="col-xl-2"></div>
                    <div  className="col-sm-12 col-xl-8">
                      <div>  {/* User Information :3 */}
                          <div  className="container-fluid accountpage-div">
                              <div  className="row">
                                  <div  className="col-xl-6 p-5 pb-3 pt-xl-5 pt-2">
                                  <h2><Link to="../"><button className="btn btn-secondary">Home</button></Link> Redeem your points</h2>
                                  </div>
                                  <div  className="col-xl-6 p-5 pt-0 pt-xl-5 pb-3">
                                    <h2  className="text-lg-end placeholder-wave">{points? <p>You currently have   <b>{points}</b> points </p>: <span className="placeholder col-12"></span> }</h2>
                                  </div>
                              </div>
                          </div>
                          <div  className="container-fluid accountpage-div">
                              <div  className="row">
                                  <div  className="col-xl-6 p-5 pt-3 pb-3">
                                    <h3>{filteredAwards.length} results</h3>
                                  </div>
                                  <div  className="col-xl-6 p-5 pt-1 pt-xl-3 pb-3 justify-content-xl-end">
                                  <div className="accountRedeem-search-box">
                                    <input 
                                        type="text" 
                                        placeholder="Search rewards..." 
                                        value={searchTerm} 
                                        onChange={e => setSearchTerm(e.target.value)} 
                                    />
                                   
                                </div>
                            </div>
                            </div>
                              <div  className="row">
                                    {filteredAwards.map((award, index) => (
                                         <div  className="col-xl-6 p-2" key={index}>
                                            <div >
                                            <Link to="/account/redeem/selection" state={award} style={{textDecoration: 'none'}}>
                                                <div  className="accountRedeem-button d-flex rounded p-0 m-3" style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/bridge.jpg'})`, backgroundSize: 'cover'}}>
                                                <div  className="accountRedeem-caption align-self-end p-3 pb-0">
                                                <p  className="accountpage-buttonText align-bottom">{award.title}</p>
                                                <hr></hr>
                                                <div  className="row">
                                                <div  className="col-sm-6"><p  className="float-start small">{award.value} points</p></div>
                                                <div  className="col-sm-6"><Redeemable currentPoints={points} value={award.value}/></div>
                                                </div>
                                                
                                                
                                                </div>
                                                </div>
                                            </Link>
                                        </div>
                                        </div>
                                    ))} 
                                </div>
                                  
                            </div>     
                                  </div>
                              </div>                        
                <div  className="col-xl-2"></div>
      
            
            </div>
        </div>
    </div>
  );
}