import { useLocation, useNavigate } from "react-router-dom";
import "../index.css"; 
import { useDarkMode } from "../navigation_components/DarkModeContext"; 

export default function TaskSuccessful() {
  const location = useLocation();
  const navigate = useNavigate();
  const { darkMode } = useDarkMode(); 
  const taskData = location.state;

  if (!taskData) {
    navigate('/account');
    return null;
  }

  const handleClaimClick = () => {
    navigate('/account');
  };

  return (
    <div className="container-fluid d-flex justify-content-center align-items-center vh-100">
      <div className="col-lg-8 p-5 text-center">
      <h2>{taskData.taskName} - Completed successfully!</h2>
        <h2>You gained 10 points!</h2>
        <p>check mark image or something similar</p>
        <button
          onClick={handleClaimClick}
          className={`custom-button ${darkMode ? "dark-mode-button" : "classic-button"}`}
        >
          Claim
        </button>
      </div>
    </div>
  );
}
