import React from "react";
import { Link } from "react-router-dom";
import FoodPageRadioButtonFoodWater from "./FoodPageRadioButtonFoodWater";

export default function FoodPage() {
    return (
      <div className="container-fluid">
        <div className="row align-items-start">
          <div className="col-lg-2"></div>
          <div className="col-lg-8 p-5 pb-3 pt-lg-5 pt-2">
            <h2><Link to="../../"><button className="btn btn-secondary">←</button></Link> Food Tracking</h2>

            {/* food/water radio */}
            <FoodPageRadioButtonFoodWater />

          </div>
          <div className="col-lg-2"></div>
        </div>
      </div>
    );
  }
  